* {
    box-sizing: border-box;
}

/* Navbar */
.ocs2-navbar {
    border-bottom: 1px solid #eee;
    min-height: 90px;
    position: fixed !important;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 999;
    margin-bottom: 90px !important;
    box-shadow: 1px 0px 5px #eee;
}

.ocs2-navbar-container {
    display: flex !important;
    justify-content: space-between !important;
}

.ocs2-nav-link {
    text-decoration: none;
    color: black;
}

.ocs2-nav-link:hover {
    color: #295fa9;
}

.ocs2-nav-order-btn {
    margin-right: 10px;
    background-color: #295fa9;
    border: 2px solid #295fa9;
    color: white;
    padding: 7px 25px;
    border-radius: 5px;
}

.ocs2-nav-order-btn:hover {
    background-color: white;
    color: #295fa9;
}

.ocs2-nav-phone-btn {
    background-color: white;
    color: #295fa9;
    border: 2px solid #295fa9;
    padding: 7px 25px;
    border-radius: 5px;
}

.ocs2-nav-phone-btn:hover {
    background-color: #295fa9;
    color: white;
}

.justify-content-center {
    margin-left: 90px;
}

#displaymenu {
    display: grid;
    grid-template-columns: repeat(2, 4fr);
    z-index: 1000;

}

.sub-menu {
    cursor: pointer;
}

.sub-menu-link {
    text-decoration: none;
    color: #333333;
}

/* Responsive Navbar */
@media only screen and (max-width: 600px) {
    .ocs2-nav-order-btn {
        margin-bottom: 20px;

    }

    .justify-content-center {
        margin-left: 0;
        margin-bottom: 20px;
    }

    .ocs2-navbar {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        flex-wrap: wrap;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .ocs2-nav-phone-btn {
        margin-bottom: 30px;
    }

    .justify-content-center {
        margin-left: 0px;
    }

    .ocs2-navbar-container {
        flex-wrap: wrap;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .ocs2-nav-order-btn {
        margin-bottom: 20px;
    }

    .justify-content-center {
        margin-left: 0;
        margin-bottom: 20px;
    }

    .ocs2-navbar {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
    }

    .ocs2-nav-phone-btn {
        margin-bottom: 30px;
    }

    .justify-content-center {
        margin-left: 0px;
    }
}


/* End Navbar */

/* Login / Register Form */
.ocs2-login-register {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #295fa9;
}

.ocs2-login-register-auth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ocs2-login-register-auth h1 {
    color: white;
    margin-bottom: 30px;
}

.ocs2-login-register-auth form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    width: 320px;
    gap: 20px;
}

.ocs2-login-register-auth input {
    padding: 10px;
    border: none;
    border-bottom: 2px solid grey;
}

.ocs2-login-register-auth button {
    padding: 10px;
    background-color: #295fa9;
    color: white;
    border: none;
    margin-top: 10px;
}

.ocs2-login-register-auth button:hover {
    background-color: #999999;
}

.ocs2-login-register-auth p {
    font-size: 14px;
    color: #295fa9;
    text-align: center;
    margin-bottom: -10px;
}

.ocs2-login-register-auth span {
    text-align: center;
}

.ocs2-login-register-auth .link {
    text-decoration: none;
    color: #295fa9;
    font-weight: bold;
}

.ocs2-login-register-auth .link:hover {
    color: #999999;
}

/* End Login / Register */


/* sample page */
.samples {
    margin: 190px 0 100px 0;
    display: flex;
}

.sidebar {
    flex: 2;
    background-color: #f3f3f3;
    margin-right: 30px;
    padding: 30px;
}

.sidebar>.title {
    font-size: 20px;
    font-weight: bold;

}

.sidebar .subtitle {
    font-size: 20px;
}

.sidebar .menu-link {
    text-decoration: none;
    color: #295fa9;
}

.posts-wrap {
    flex: 5;
}

.posts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 30px;
}

.post {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eee;
    padding-bottom: 20px;
}

.posts-wrap nav {
    display: flex;
    margin: 0;
    justify-content: center;
}

.posts-wrap .pagination {
    margin: 0;
    padding: 0;
}

.page-link {
    color: #333333 !important;
    outline: none;
}

.active>.page-link {
    background-color: #295fa9 !important;
    color: white !important;
    outline: none !important;
    border: 1px solid #295fa9 !important;
}

.img {
    width: 297px;
}

.img img {
    width: 100%;
    margin-bottom: 20px;
}

.content .postlink {
    text-decoration: dotted;
    color: #295fa9;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
}

.postlink h1 {
    font-size: 20px !important;
    color: #333333;
}

.postlink button {
    border: none;
    padding: 7px 25px;
    background-color: #295fa9;
    color: white;
    border-radius: 5px;
}

.postlink button:hover {
    background-color: #999999;
}

.hline {
    margin: 20px 20px 10px 20px;
}

.postdesc,
.posttitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* celculator */
.sample-form-outerbox {
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box !important;
    margin-bottom: 20px;
}

.sidebar-top {
    background-color: #295fa9;
    border-radius: 15px 15px 0 0;
    box-sizing: border-box !important;
    text-align: center;
    padding: 20px 15px 3px 15px;
}

.sidebar-top p {
    font-weight: 600;
    font-size: 18px
}

.sidebar-bottom {
    border: 2px solid #295fa9;
    border-radius: 0 0 15px 15px;
    padding: 30px;
    box-sizing: border-box !important;
}

.form-top {
    display: flex;
    gap: 20px;
}

.contact-form {
    box-sizing: border-box !important;
}

.bottom-text {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    color: #545454;
}

.contact-form label {
    margin-bottom: 5px;
}

#u-subject {
    margin-bottom: 20px;
}

.contact-form input {
    padding: 13px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #e3e3e3;
}

.form-guarentee {
    margin-top: 20px;
    margin-bottom: 0;
    text-align: center;
}
.error-text{
    color: red;
    font-size: 12px;
  
}

/* Samples*/
.hero-section {
    background-color: #EEEEEE;
    /* background-image: url(https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png); */
    margin-top: 90px;
    width: 100%;
    height: 450px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 200px;
    /* background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
}

.text-wrap {
    word-wrap: break-word !important;
}

.main-box h1 {
    color: #295fa9;
    font-size: 50px;
    text-align: center;
    font-weight: 800;
}

.main-box h1 span {
    color: #545454;
    font-size: 50px;
    text-align: center;
    font-weight: 800;
}

.main-box p {
    font: 600;
    font-size: 18px;
    text-align: center;
}

.sample-hero-links {
    display: flex;
    gap: 20px;
    justify-content: center;
    background-color: #F9F9F9;
    padding: 15px;
    border-radius: 5px;
}

.sample-hero-links a {
    color: #295fa9;
    text-decoration: none;
    font-weight: 500;
    font-size: 19px;
    text-align: center;
}

.sample-hero-links a:hover {
    color: #545454;
    text-decoration: none;
    font-weight: 500;
}

.sample-main-container {
    width: 100%;
    display: flex;
    margin-bottom: 100px;
    padding: 0 100px;
}

.main-container-sl {
    width: 70%;
    margin-right: 30px;
    box-shadow: 4px 4px 12px #999999;
    border-radius: 10px;
    box-sizing: border-box;
    height: auto;
}
.text-box{
    width: 97%;
}

.main-container-sl h2 {
    font-size: 36px;
}

.main-container-sl h3 {
    font-size: 28px;
}

.main-container-sl img {
    width: 100%;
 
    margin-bottom: 20px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
#table_img{
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

 
}
.table{
    box-sizing: border-box !important;
}
.table td{
    border: 1px solid black;
}

.main-container-sl-content {
    padding: 20px;
    word-wrap: break-word;
}

.main-container-sr {
    width: 30%;
    box-sizing: border-box;

}

.main-container-sr h2 {
    font-size: 32px;
    font-weight: 600;
    color: #295fa9;
    border-bottom: #eee 2px solid;
}

.main-container-related-post {
    padding: 20px;
    box-shadow: 4px 4px 12px #999999;
    border: #295fa9 2px solid;
    border-radius: 10px;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}

.main-container-sr ul {
    margin: 10px 0;
}

.main-container-sr li {
    font-size: 18px;
    font-weight: 500;
}

.main-container-sr a {
    text-decoration: none;
    color: #545454;
}

.main-container-sr a:hover {
    text-decoration: none;
    color: #295fa9;
}

.main-container-category {
    padding: 20px;
    box-shadow: 4px 4px 12px #999999;
    border: #295fa9 2px solid;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
}

.main-container-services {
    padding: 20px;
    box-shadow: 4px 4px 12px #999999;
    border: #295fa9 2px solid;
    border-radius: 10px;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}

.sample-carousel {
    border-radius: 5px;
    width: 96%;
    padding: 20px;
    display: inline-block;
    text-align: center;
    background-color: #295fa9;
    color: white;
    font-weight: 700;
    font-size: 20px;
}

.sample-more-details {
    width: 100%;
    margin-bottom: 20px;
}

.sample-more-details-box {
    display: flex;
    justify-content: center;
    align-items: center;
}
/* sample popup */
.the-pop-up{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000000dc;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tpu-box{
    border: 2px solid #295fa9;
    border-radius: 10px;
    width: 400px;
    padding: 30px;
    background-color: white;
}
.tpu-info{
    text-align: center;
    margin-bottom: 15px;
    font-size: 14px;
}
.tpu-binfo{
    font-size: 14px;
    margin-top: 15px;
}

/* responsive */
@media only screen and (max-width: 600px){
    .tpu-box{
        width: 100%;
        margin: 0 20px;
    } 
}


/* tab sample responsive */
@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .hero-section {
        background-color: #EEEEEE;
        margin-top: 90px;
        width: 100%;
        height: 450px;
        margin-bottom: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;

    }
    .table{
    
        font-size: 15px;
    }
    .table td{
        display: flex;
        flex-direction: column;
    }

    .main-box h1 {
        color: #295fa9;
        font-size: 40px;
        text-align: center;
        font-weight: 800;
    }

    .main-box h1 span {
        color: #545454;
        font-size: 40px;
        text-align: center;
        font-weight: 800;
    }

    .main-box p {
        font: 600;
        font-size: 17px;
        text-align: center;
    }

    .sample-hero-links {

        display: flex;
        gap: 20px;
        justify-content: center;
        padding: 0 20px;


    }

    .text-wrap {
        word-wrap: break-word !important;
    }

    .sample-hero-links a {
        color: #295fa9;
        text-decoration: none;
        font-weight: 500;
        font-size: 17px;
    }

    .sample-main-container {
        width: 100%;
        display: flex;
        margin-bottom: 100px;
        padding: 0 40px;
        box-sizing: border-box;
    }

    .main-container-sl {
        width: 65%;
        box-shadow: 4px 4px 12px #999999;
        border-radius: 10px;
        box-sizing: border-box;
    }
    .text-box{
        width: 96%;
    }

    .main-container-sl h2 {
        font-size: 30px;
    }

    .main-container-sl h3 {
        font-size: 26px;
    }

    .main-container-sr {
        width: 35%;
        box-sizing: border-box;
    }

    .main-container-sr h2 {
        font-size: 28px;
        font-weight: 600;
        color: #295fa9;
        border-bottom: #eee 2px solid;
    }

    .main-container-sr li {
        font-size: 17px;
        font-weight: 500;
    }

    .main-container-related-post {
        padding: 20px;
        box-shadow: 4px 4px 12px #999999;
        border: #295fa9 2px solid;
        border-radius: 10px;
        margin-bottom: 20px;
        width: 245px;
        box-sizing: border-box;
    }

    .main-container-category {
        padding: 20px;
        box-shadow: 4px 4px 12px #999999;
        border: #295fa9 2px solid;
        border-radius: 10px;
        width: 245px;
        box-sizing: border-box;
    }

    .main-container-services {
        padding: 20px;
        box-shadow: 4px 4px 12px #999999;
        border: #295fa9 2px solid;
        border-radius: 10px;
        margin-bottom: 20px;
        width: 245px;
        box-sizing: border-box;
    }

    .sample-carousel {
        font-weight: 600;
        font-size: 14px;
        width: 100%;
        border-radius: 5px;
        padding: 10px;
    }

    .sample-more-details {
        width: 260px;
        margin-bottom: 20px;
        border-radius: 5px;
    }

    .sample-form-outerbox {
        border-radius: 15px;
        width: 245px;
        box-sizing: border-box !important;
        margin-bottom: 20px;
    }

    .sidebar-top p {
        font-weight: 600;
        font-size: 16px
    }

    .bottom-text {
        text-align: center;
        margin-bottom: 20px;
        font-weight: 600;
        color: #545454;
        font-size: 15px;
    }
}

/* mobile sample responsive */
@media only screen and (max-width: 600px) {
    .hero-section {
        background-color: #EEEEEE;
        margin-top: 90px;
        width: 100%;
        height: 450px;
        margin-bottom: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;

    }
    .table{
    
        font-size: 14px;
    }
    .table td{
        display: flex;
        flex-direction: column;
    }
    

    .main-box h1 {
        color: #295fa9;
        font-size: 32px;
        text-align: center;
        font-weight: 800;
    }

    .main-box h1 span {
        color: #545454;
        font-size: 32px;
        text-align: center;
        font-weight: 800;
    }

    .main-box p {
        font: 600;
        font-size: 16px;
        text-align: center;

    }

    .text-wrap {
        word-wrap: break-word !important;
    }

    .sample-hero-links {
        display: flex;
        gap: 10px;
        justify-content: center;
        padding: 0 20px;

    }

    .sample-hero-links a {
        color: #295fa9;
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
    }

    .sample-main-container {
        width: 100%;
        display: block;
        margin-bottom: 100px;
        padding: 0 20px;
    }

    .main-container-sl {
        width: 100%;
        box-shadow: 4px 4px 12px #999999;
        border-radius: 10px;
        margin-bottom: 20px;
    }

    .main-container-sl h2 {
        font-size: 28px;
    }

    .main-container-sl h3 {
        font-size: 22px;
    }

    .main-container-sr {
        width: 100%;
    }

    .main-container-sr h2 {
        font-size: 26px;
        font-weight: 600;
        color: #295fa9;
        border-bottom: #eee 2px solid;
    }

    .main-container-sr li {
        font-size: 16px;
        font-weight: 500;
    }

    .main-container-related-post {
        padding: 20px;
        box-shadow: 4px 4px 12px #999999;
        border: #295fa9 2px solid;
        border-radius: 10px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
    }

    .main-container-category {
        padding: 20px;
        box-shadow: 4px 4px 12px #999999;
        border: #295fa9 2px solid;
        border-radius: 10px;
        width: 100%;
        box-sizing: border-box;
    }

    .main-container-services {
        padding: 20px;
        box-shadow: 4px 4px 12px #999999;
        border: #295fa9 2px solid;
        border-radius: 10px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
    }

    .sample-carousel {
        font-weight: 600;
        font-size: 16px;
        width: 100%;
        border-radius: 5px;
        padding: 10px;
    }

    .sample-more-details {
        width: 100%;
        margin-bottom: 20px;
        border-radius: 5px;
    }

    .sample-form-outerbox {
        border-radius: 15px;
        width: 100%;
        box-sizing: border-box !important;
        margin-bottom: 20px;
    }

    .sidebar-top p {
        font-weight: 600;
        font-size: 16px
    }

    .bottom-text {
        text-align: center;
        margin-bottom: 20px;
        font-weight: 600;
        color: #545454;
        font-size: 15px;
    }
}

/* List Category Sample*/
.sample-list-category {
    margin-bottom: 100px;
    justify-content: center;

}

.sample-list-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
}

.sample-list-category h4 {
    font-size: 24px;
    color: black;
    font-weight: bold;
    text-align: center;
}

.sample-list-category h2 {
    color: #295fa9;
    font-weight: bold;
    font-size: 40px !important;
    text-align: center;
}

.sample-list-category ul {
    margin: 40px 0;
}

.sample-list-category li {
    font-size: 18px;
    font-weight: 500;
}

.sample-list-category a {
    text-decoration: none;
    color: #333333;
}

.sample-list-category a:hover {
    text-decoration: none;
    color: #295fa9;
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {

    .sample-list-category h4 {
        color: black;
        font-weight: bold;
        text-align: center;
    }

    .sample-list-category h2 {
        color: #295fa9;
        font-weight: bold;
        text-align: center;
    }
    .sample-list-category li {
        font-size: 17px;
        font-weight: 500;
    }
}
@media only screen and (max-width: 600px) {
    .sample-list-category li {
        font-size: 16px;
        font-weight: 500;
    }
    .sample-list-category h4 {
        color: black;
        font-weight: bold;
        text-align: center;
        font-size: 22px;
    }

    .sample-list-category h2 {
        color: #295fa9;
        font-weight: bold;
        text-align: center;
        font-size: 25px !important;
        padding:0 5px;
    }
}

/* mobile responsive */
@media only screen and (max-width: 600px) {
    .samples {
        flex-direction: column;
        gap: 20px;
    }

    .sidebar {
        width: 100%;
    }

    .posts {
        grid-template-columns: repeat(1, 1fr);
    }
}
/* service hero section*/
.s-hero1 {
    background-color: #eeeeee;
    background-image: url("images/herosection.png");
    background-size: cover;
   margin-top: 90px;
   margin-bottom: 80px;
  
}
.ocs2-main-herosection{
width: 100%;
padding: 40px 0;
justify-content: center;
}
.ocs2-main-herosection h1{
    color: #295fa9;
    font-size: 45px;
    text-align: center;
    font-weight: bold;
}

.rating-text{
    font-size: 18px;
    text-align: center;
}
.ocs2-ratting1{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    align-items: center;
}
.ocs2-ratting-stars1{
    display: flex;
    margin-bottom: -5px;
}
.ocs2-ratting p{
    font-size: 14px !important;
    margin-bottom: 0;
}
.ocs2-ratting-provider1{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #999999;
    border-radius: 5px;
    padding:0px 10px;
    width: 270px;

}
.ocs2-ratting-favicon1{
    height: 40px;
    width: auto;
    margin-right: 10px;
}
.ocs2-ratting-text{
  padding-top: 15px;
  font-size:18px
}
.ocs2-trustpilot-icon1{
    font-size: 40px;
    color: #00B67A;
    margin-right: 7px;
}
@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .ocs2-ratting-text{
        padding-top: 15px;
        font-size:17px
      }
    .rating-text{
        font-size: 17px;
        text-align: center;
    }
    .ocs2-main-herosection h1{
        color: #295fa9;
        font-size: 36px;
        text-align: center;
        font-weight: bold;
    }
   
    .ocs2-ratting-provider1{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #999999;
        border-radius: 5px;
        padding:0px 10px;
        width: 250px;
    
    }
}
@media only screen and (max-width: 600px) {
    .ocs2-main-herosection h1{
        color: #295fa9;
        font-size: 34px;
        text-align: center;
        font-weight: bold;
    }
 
    .rating-text{
        font-size: 16px;
        text-align: center;
    }
    .ocs2-ratting1{
        flex-direction: column;
        width: auto;
        gap: 10px;
        margin-bottom: 50px;
     }
     .ocs2-ratting-provider1{
        justify-content: center;
     width: 90%;
     }
     .ocs2-ratting-favicon1{
        height: 50px;
        width: auto;
        margin-right: 10px;
    }
    .ocs2-trustpilot-icon1{
        font-size: 50px;
        color: #00B67A;
        margin-right: 7px;
    }
    .ocs2-ratting-text{
        font-size: 16px;
    }
}

/* tab responsive */
@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .samples {
        flex-direction: column;
        gap: 20px;
    }

    .sidebar {
        width: 100%;
    }

    .posts {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* signgle post page */

.singlepost {
    display: flex;
    gap: 50px;
    margin: 190px 0 100px 0;
}

.singlepost .content {
    width: 70%;
}

.singlepost .content img {
    width: 100%;
    height: auto;
}

.singlepost .menu {
    flex: 2;
}

.postaction {
    display: flex;
    gap: 20px;
}

.postedit,
.postdelete {
    width: 40px;
    height: 40px;
    margin-top: 30px;
    font-size: 20px;
    background-color: #295fa9;
    color: #ffffff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
}

.postedit:hover,
.postdelete:hover {
    background-color: #999999;
}

.singlepost .content h1 {
    margin: 20px 0;
    color: #333;
}

.singlepost .content p {
    font-size: 18px;
    text-align: justify;
}

/* mobile responsive */
@media only screen and (max-width: 600px) {
    .singlepost {
        flex-direction: column;
    }

    .singlepost .content {
        width: 100%;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .singlepost {
        flex-direction: column;
    }

    .singlepost .content {
        width: 100%;
    }
}

/*  newpopup*/
.popup-container{
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 0%, 0.681);
    position: fixed;
    display: grid;
    place-items:center;
    z-index: 1000;
    top: 0;
    left: 0;
}
.new-popup{
    width: 25%;
    background-color: white;
    border-radius: 16px;
}
/* Side bar */
.sidebarform {
    background-color: #295fa9;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 5px;
}

.formheading {
    background-color: #999999;
    color: white;
    font-size: 25px;
    text-align: center;
    padding: 7px;
    border-radius: 5px;
}

.sidebarform input {
    width: 100%;
    padding: 7px;
}

.sidebarform button {
    width: 150px;
    border: none;
    background-color: white;
    border-radius: 5px;
    padding: 7px 25px;
    color: #295fa9;
}

.sidebarform button:hover {
    background-color: #999999;
}

.postlist {
    margin-bottom: 100px;
}

.postlist h2 {
    font-size: 20px;
    margin-bottom: 20px;
}

.postlist h3 {
    font-size: 16px;
    margin-bottom: 0px;
}

.postlist .lpost {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 5px;
}

.lpost p {
    width: 116px;
    margin-bottom: 0px;
    cursor: pointer;
}

.lpost .openbook {
    font-size: 40px;
    color: #295fa9;
}

.read-more {
    text-decoration: none;
    color: #295fa9;
}

/* write page */
.write {
    margin: 100px 0;
    display: flex;
    gap: 50px;
}

.write .content {
    flex: 5;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.editorcontainor {
    height: 350px;
    overflow: auto;
}

.editorcontainor .editor {
    height: 300px;
}

.write .content input {
    padding: 10px;
    border: 1px solid lightgray;
}

.write .createmenu {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.createmenu .item {
    border: 1px solid lightgray;
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    color: #555;
}

.createmenu .item h2 {
    font-size: 20px;
}

.createmenu .item .file {
    text-decoration: underline;
    cursor: pointer;
}

.createmenu .item .buttons {
    display: flex;
    justify-content: space-between;
}

.createmenu .item .buttons button {
    cursor: pointer;
    color: white;
    background-color: #295fa9;
    padding: 7px 25px;
    border: none;
    border-radius: 5px;
}

.createmenu .item .buttons button:hover {
    background-color: #999999;
}

.createmenu .item .cat {
    display: flex;
    align-items: center;
    gap: 2px;
    color: #295fa9;
}

/* mobile responsive */

@media only screen and (max-width: 600px) {
    .write {
        flex-direction: column;
    }
}

/* tab responsive */

@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .write {
        flex-direction: column;
    }
}


/* order page */
.order-page {
    margin: 190px 0 100px 0;
}

.order-page-box {
    background-color: #eee;
    padding: 30px;
}

.o-p-title {
    font-size: 30px;
    font-weight: bold;
}

.order-form {
    width: 100%;
    margin-top: 30px;
}

.order-form-top {
    width: 100% !important;
    display: flex;
    gap: 30px;
}

.order-form-flex {
    width: 50%;
}

.order-form select,
.order-form input {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 20px;
}

.order-form-flex-3 {
    width: 33%;
}

.textarea {
    width: 100%;
}

#project-details {
    width: 100%;
    padding: 10px 15px;
    height: 200px;
    margin-bottom: 20px;
}

/* admin page */
.admin-page {
    background-color: #295fa9;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-page-inner {
    background-color: white;
    border-radius: 15px;
    width: 70%;
    padding: 30px;
}

.admin-page-inner>span {
    font-size: 30px;
    font-weight: bold;
}

.admin-page-links {
    list-style: none;
    border: 1px solid #999999;
    border-radius: 5px;
    width: 300px;
    padding: 20px;
    margin-top: 20px;
}

.admin-page-links li {
    text-decoration: none;
    margin: 5px 0;
}

.user span {
    font-weight: bold;
}

.user-flex {
    display: flex;
    justify-content: space-between;
}

.user img {
    width: 200px;
    height: auto;
    border-radius: 200px;
    margin-bottom: 20px;
}

.user {
    margin-top: 30px;
    text-align: center;
}

.c-link {
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
    color: #295fa9;
}

/* popup */
#popupBox {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.895);
    z-index: 999;
    /* display: none; */
}

.popup-inner-box {
    width: 700px;
    border-radius: 10px;
    background-color: #999999;
    display: flex;
    gap: 30px;
    padding: 30px;
    align-items: center;
}

.popup-left,
.popup-right {
    width: 50%;
}

.popup-left {
    background-image: url(images/popupimage.png);
    background-size: contain;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
}

.popup-form {
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
}

.popup-form-title {
    font-size: 20px;
    font-weight: bold;
    color: white;
    line-height: 0px;
}

.popup-form input {
    width: 100%;
    padding: 7px;
    outline: none;
    margin-bottom: 10px !important;
}

/* popup responsive */
@media only screen and (max-width: 600px) {
    .popup-inner-box {
        flex-direction: column;
        width: 85%;
        padding: 30px;
    }

    .popup-left,
    .popup-right {
        width: 90%;
    }
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
    .popup-inner-box {
        flex-direction: column;
        width: 75%;
        padding: 30px;
    }

    .popup-left,
    .popup-right {
        width: 90%;
    }
}

/* terms */
.terms-main {
    margin: 190px 0 100px 0;
}

/* bsn hero section */
.bsn-hero {
    margin-top: 90px;
    margin-bottom: 100px;
    padding: 100px 0;
    background-image: url(images/bsnhero.jpg);
    height: 70vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: start;
    background-color: #295ea944;
}

.bsn-hero-title {
    background-color: white;
    box-shadow: 2px 2px 20px #eeeeee;
    width: 600px;
    height: 300px;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bsn-hero-title h1 {
    font-size: 50px !important;
    font-weight: bold;
    color: #545454;
}

.bsn-line {
    width: 100px !important;
    height: 5px !important;
    background-color: #295fa9;
    margin-bottom: 7px;
    border-radius: 5px;
    margin-left: 5px;
}

/* degree detail section */
.degree-detail {
    margin-bottom: 100px;

}

.degree-details-inner {
    display: flex;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.degree-uni-details {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
}

.degree-details-offer {
    width: 30%;
    height: auto;
    padding: 30px;
    background-color: #295fa9;
    display: flex;
    align-items: center;
    border-radius: 0 10px 10px 0;
}

.degree-details-offer img {
    width: 100%;
    height: auto;
}

.free-quote-btn {
    width: 220px !important;
    text-align: center;
}

.bsn-uni-phone {
    color: #295fa9 !important;
}

.bsn-uni-details p,
.bsn-uni-details strong {
    color: #295fa9 !important;
}

.bsn-order-box {
    padding-top: 16px;
}

.bsn-br {
    height: 5px;
    width: 350px;
    background-color: #295fa9;
    margin-bottom: 20px;
    margin-top: 10px;
    border-radius: 5px;
}

/* bsn features */
.bsn-features {
    margin-bottom: 100px;
}

.bsn-features-detail {
    text-align: center;
    padding-bottom: 30px;
}

.bsn-features-detail h2 {
    color: #295fa9;
}

.bsn-features-list {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr);
}

/* BSN faqs */
.bsn-faqs {
    margin-top: 100px;
    margin-bottom: 100px;
}

.bsn-faqs-inner {
    display: flex;
    gap: 30px;
}

.bsn-faqs-image {
    background-image: url(images/Faqs.jpg);
    background-size: cover;
    width: 30%;
}

.accordian-boxes-faqs {
    width: 70%;
    margin-top: -20px;
}

/* service pages */
.services {
    padding: 0 110px 0 110px;
    margin-bottom: 100px;
}

.s-hero {


    background-color: #eeeeee;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url("images/herosection.png");
    background-size: cover;
}

.s-hero-top {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.sherotop-left {
    width: 85%;
    display: grid;
}

.cal-box {
    margin-bottom: 30px;
    width: 50%;
}

.s-hero-top .stop {
    font-weight: bold;
    color: #333;
    font-size: 25px;
}

.s-hero-top h1 {
    font-weight: bold;
    font-size: 40px !important;
    color: #295fa9;
}

.s-cta {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    background-color: #999999;
    padding: 20px;
    border-radius: 10px 10px 0 0;
    color: white;
}

.s-cta-inner {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
}

.s-cta-inner-box {
    width: 55%;
}

.s-cta-inner-btn {
    display: flex;
    align-items: center;
}

.hirebtn {
    text-decoration: none;
}

@media only screen and (max-width: 600px) {
    .services {
        padding: 0 20px;
    }

    .s-hero {
        height: auto;
    }

    .s-hero-top {
        flex-direction: column;
    }

    .sherotop-left {
        width: 100%;
        text-align: center;
        gap: 10px;
        padding-top: 20px;
    }

    .cal-box {
        width: 100%;
    }

    .s-hero-top h1 {
        font-size: 25px !important;
    }

    .s-cta {
        flex-direction: column;
    }

    .s-cta-inner {
        flex-direction: column;
    }

    .s-cta-inner-box {
        width: 100%;
        text-align: center;
    }

    .s-cta-inner-btn {
        display: grid;
        text-align: center;
        place-items: center;
    }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
    .services {
        padding: 0 50px;
    }

    .s-hero {
        padding-top: 20px;
        height: auto;
    }

    .s-hero-top {
        flex-direction: column;
    }

    .sherotop-left {
        width: 100%;
        text-align: center;
        gap: 10px;
        padding-top: 20px;
    }

    .cal-box {
        width: 100%;
    }

    .s-cta {
        flex-direction: column;
        gap: 10px;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {
    .s-cta {
        flex-direction: column;
        gap: 10px;
    }
}

/* next */

.freesamples {
    text-align: center;
}

.freesamples h2 {
    font-weight: bold;
    font-size: 40px !important;
    color: #295fa9;
}

.sampleboxes {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.singlebox {
    border: 1px solid #295fa9;
    border-radius: 10px;
    display: grid;
    gap: 20px;
}

.singlebox img {
    width: 100% !important;
    height: auto;
    border-radius: 10px 10px 0 0;
}

.text {
    padding: 0 10px;
}

.singlebox h3 {
    font-size: 22px !important;
    text-decoration: none !important;
}

.textdec {
    text-decoration: none;
    color: #295fa9;
}

@media only screen and (max-width: 600px) {
    .freesamples h2 {
        font-size: 25px !important;
    }

    .sampleboxes {
        grid-template-columns: repeat(1, 1fr);
    }

    .singlebox img {
        width: 100%;
    }
}

@media only screen and (min-width: 601px) and (max-width: 1050px) {
    .sampleboxes {
        grid-template-columns: repeat(2, 1fr);
    }

    .singlebox img {
        width: 100%;
    }
}

@media only screen and (min-width: 1051px) and (max-width: 1250px) {
    .sampleboxes {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* next */

.s-section {
    display: flex;
    gap: 30px;
}

.s-left {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 50%;
    gap: 30px;
}

.s-single {
    box-shadow: 2px 2px 10px #eeeeee;
    border-radius: 10px;
    padding: 20px;
}

.icon {
    font-size: 40px;
    color: #295fa9;
    margin-bottom: 20px;
}

.s-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.s-right h2,
.cta h2 {
    font-weight: bold;
    font-size: 40px !important;
    color: #295fa9;
}

.s-right span {
    font-weight: bold;
    color: #333;
    font-size: 25px;
}

@media only screen and (max-width: 600px) {
    .s-section {
        flex-direction: column;
    }

    .s-left {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }

    .s-right {
        width: 100%;
        text-align: center;
    }

    .s-right h2,
    .cta h2 {
        font-size: 25px !important;
    }

    .btn-box {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
    }
}

@media only screen and (min-width: 601px) and (max-width: 1150px) {
    .s-section {
        flex-direction: column;
    }

    .s-left {
        width: 100%;
    }

    .s-right {
        width: 100%;
    }
}

/* next */
.cta {
    text-align: center;
    background-color: #eee;
    padding: 70px 110px;
}

.contactform {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 30px;
}

.inputfield {
    padding: 10px;
    width: 300px;
}

.inputbtn {
    padding: 12px 35px;
    background-color: #295fa9;
    color: white;
    border: none;
    border-radius: 2px;

}


@media only screen and (max-width: 600px) {
    .cta {
        padding: 30px 20px;
    }

    .contactform {
        flex-direction: column;
    }

    .inputfield {
        width: 100%;
    }
}

/* next */

.processboxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: #eeeeee;
    padding: 30px;
    border-radius: 10px;
    gap: 30px;
}

.singleprocess {
    border: 1px solid #295fa9;
    border-radius: 10px;
    padding: 20px;
}

@media only screen and (max-width: 600px) {
    .processboxes {
        grid-template-columns: repeat(1, 1fr);
    }
}
/* sticky from */
.f-sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
}
.main-container-uni{
    width: 70%;
    margin-right: 30px;
}
.main-container-sl-uni{
    border-radius: 10px;
    box-shadow: 0px 0px 10px #9E9E9E;
}
/* accordion */
.accordian-boxes-new{
    margin-top: 50px;
    box-shadow: 0px 0px 10px #878787;
    border-radius: 10px;
    padding: 20px 20px 50px 20px;
}
.accordian-boxes-new .accordion-title{
    font-size: 20px;
}
@media only screen and (max-width: 600px) {
    .main-container-uni{
        width: 100%;
        margin-right: 0px;
    }
    .main-container-sl-uni{
        padding: 0;
    }
    .accordian-boxes-new{
        margin-bottom: 50px;
    }
}
.whatsapp-icon{
    position: fixed;
    left: 0;
    bottom: 0;
    margin: 35px;
}
.fa-square-whatsapp{
    font-size: 50px;
    color: #24CC63;
}