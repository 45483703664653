*{
    font-family: 'poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 0;
    margin: 0;
    letter-spacing: 1px;
    letter-spacing: 0.4px;
    box-sizing: border-box;
    
}
button{
    border: none;
    padding: 6px 35px;
    background-color: #295fa9;
    border: 2px solid #295fa9;
    color: white;
    border-radius: 5px !important;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none !important;
}

/* Header */


/* Hero section */
.ocs2-main-box{
    background-image: url("images/herosection.png");
    background-size: cover;
    margin-top: 90px;
    width: 100%;
    box-sizing: border-box;
}
.ocs2-main{
    /* height: 90vh; */
    padding: 38px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.ocs2-mainHeading{
    width: 65%;
}
.ocs2-form{
    width: 35%;
}
.ocs2-main h1{
    font-size: 35px !important;
    font-weight: bold;
    color: #545454;
}
.ocs2-main h2{
    font-size: 35px !important;
    font-weight: bold;
    color: #545454;
}
.ocs2-topHeading{
    color: #295fa9;
}
.ocs2-topText{
    margin: 17px 0px;
    padding:0 10px;
    border-left: 5px solid #295fa9;
}
.ocs2-main ul{
    width: 680px;
    list-style: none;
    margin: 25px 0 25px -20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.ocs2-main ul li{
    margin: 7px 0px;
    font-size: 18px;
    display: flex;
    align-items: center;

}
.ocs2-main li{
    width: 330px;
}
.ocs2-list-icon{
    font-size: 30px;
    color: #295fa9;

}
.ocs2-ratingCount{
    color: #295fa9;
}
.ocs2-main p{
    font-size: 20px;
}
.ocs2-ratting{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    width: 650px;
}
.ocs2-ratting-stars{
    display: flex;
    margin-bottom: -5px;
}
.ocs2-ratting p{
    font-size: 14px !important;
    margin-bottom: 0;
}
.ocs2-ratting-provider{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #999999;
    border-radius: 5px;
    padding: 10px;
    margin-right: 20px;
}
.ocs2-trustpilot-icon{
    font-size: 35px;
    color: #00B67A;
    margin-right: 7px;
}
.ocs2-ratting-favicon{
    height: 35px;
    width: auto;
    margin-right: 7px;
}
.ocs2-banner-capella{
    border: 2px dashed #295fa9;
    border-radius: 10px;
    margin: 30px 0;
    padding: 10px 15px;
    width: 600px;
    display: flex;
    justify-content: space-between;
    /* background-color: #00000026; */
}
.ocs2-banner-capella-path{
    display: flex;
    align-items: center;
}
.ocs2-capella-icon{
    color: #999999;
    font-size: 30px;
    margin-right: 10px;
}
.ocs2-banner-capella-path p{
    font-size: 12px;
    margin-bottom: 0px;
}
.ocs2-banner-capella-path h3{
    color: #295fa9;
    font-size: 20px;
    margin-bottom: 0;
}
.f-path-pointer{
    cursor: pointer;
    background-color: #295fa9;
    color: white !important;
    padding: 7px;
    text-align: center;
    width: 230px;
    border-radius: 5px;
    margin-bottom: 10px !important;
}
.f-path-pointer:hover{
    color: #295fa9 !important;
    background-color: #999999;
}

/* hero section responsive */
@media only screen and (max-width: 600px){
 .ocs2-main-box{
    width: 100% !important;
 }
    .ocs2-main{
    padding: 20px 10px;
    flex-direction: column;
    height: auto;
    
 }  
 
 .ocs2-mainHeading{
    width: 100%;
 } 
 .ocs2-main h1{
    font-size: 25px !important;
 }
 .ocs2-main ul{
    grid-template-columns: repeat(1, 1fr);
    width: auto;
 }
 .ocs2-banner-capella{
    flex-direction: column;
    width: auto;
 }
 .ocs2-ratting{
    flex-direction: column;
    width: auto;
    gap: 10px;
    margin-bottom: 50px;
 }
 .ocs2-ratting-provider{
    justify-content: center;
    margin: 0;
 }
 .ocs2-form{
    width: 100%;
 }
 .ocs2-countdown-app h2{
    font-size: 20px !important;
 }
 .ocs2-calculator-disount-bold{
    padding: 7px 15px !important;
 }
}

/* hero tab responsive*/

@media only screen and (min-width: 601px) and (max-width: 1200px){
    .ocs2-main{
        flex-direction: column;
        padding: 50px 10px !important;
        height: auto;
    }
    .ocs2-mainHeading{
        width: 100%;
     } 
     .ocs2-main h1{
        font-size: 25px !important;
     }
     .ocs2-main ul{
        grid-template-columns: repeat(1, 1fr);
        width: auto;
     }
     .ocs2-banner-capella{
        flex-direction: row;
        width: auto;
     }
     .ocs2-ratting{
        flex-direction: column;
        width: auto;
        gap: 10px;
        margin-bottom: 50px;
     }
     .ocs2-ratting-provider{
        justify-content: center;
        margin: 0;
     }
     .ocs2-form{
        width: 100%;
     }
     .ocs2-countdown-app h2{
        font-size: 20px !important;
     }
     .ocs2-calculator-disount-bold{
        padding: 7px 15px !important;
     }
}

/* features section */
.ocs2-features{
    padding-bottom: 70px;
}
.ocs2-features-heading{
    text-align: center;
    font-size: 35px;
}
.thin-text{
    font-weight: 300;
}
.ocs2-features-headind-colortext{
    color: #295fa9;
}
.ocs2-single-feature{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    background-color: #F9F9F9;
    border: 2px solid #F9F9F9;
}
.ocs2-single-feature:hover{
    border: 2px solid #295fa9;
    cursor: pointer;
}
.ocs2-features-icon{
    font-size: 55px;
    margin: 20px 0px;
    color: #295fa9;
}
.ocs2-feature-icon-title{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
}
.ocs2-features-grid{
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
}

.ocs2-features-content{
    display: flex;
    align-items: center;
    margin-top: 70px;
    justify-content: space-between;
}
.ocs2-features-content-box{
    width: 48.5%;
    border: 2px solid #295fa9;
    border-radius: 15px;
}
.ocs2-features-content-title{
    background: linear-gradient(90deg, #295fa9, #999999);
    border-radius: 13px 13px 0 0;
    display: flex;
    justify-content: space-between;
    font-size: 23px;
    font-weight: bold;
    padding: 15px;
    color: white;
}
.ocs2-features-content-table{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    padding: 15px;
}
.ocs2-features-content-table-list{
    font-size: 12px;
    border: 1px dashed #295fa9;
    padding: 7px 15px;
    border-radius: 18px;
    display: flex;
    justify-content: space-between;
}
.ocs2-features-content-table-list:hover{
    background-color: #999999;
    color: white;
}
.ocs2-features-content-table-list del{
    color: #295fa9;
    font-weight: bold;
}
.ocs2-badge img{
    width: auto;
    height: 200px;
}
.ocs2-badge{
    width: 50%;
    display: flex;
    justify-content: space-around;
}


/* features responsive */
@media only screen and (max-width: 600px){
    .ocs2-features{
        padding: 0 10px;
    }
    .ocs2-features-heading{
        font-size: 25px;
    }
    .ocs2-features-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    .ocs2-features-content{
        flex-direction: column;
    }
    .ocs2-features-content-box{
        width: 100%;
        margin-bottom: 70px;
    }
    .ocs2-badge img{
        height: auto;
        width:50%;
        margin-bottom: 30px;
    }
    .ocs2-features-content-table{
        grid-template-columns: repeat(1, 1fr);
    }
    .ocs2-badge{
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

}

/* tab responsive features section */

@media only screen and (min-width: 601px) and (max-width: 1200px){
    .ocs2-features{
        padding: 0 10px;
    }
    .ocs2-features-heading{
        font-size: 25px;
    }
    .ocs2-features-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    .ocs2-features-content{
        flex-direction: column;
    }
    .ocs2-features-content-box{
        width: 100%;
        margin-bottom: 70px;
    }
    .ocs2-badge img{
        height: auto;
        width: 100%;
        margin-bottom: 30px;
    }  
}

/* bannerone */

.ocs2-bannerone{
    background: linear-gradient(90deg, #295fa9, #999999);
    padding: 50px 160px;
    margin-bottom: 70px;
    display: flex;
    align-items: center;
}
.ocs2-bannerone-detail-title{
    font-weight: bold;
    color: white;
    font-size: 20px;
}
.ocs2-bannerone-detail-text{
    margin: 15px 0 35px 0;
    font-weight: 18px;
    color: white;
}
.ocs2-bannerone-image-box{
    height: 250px;
    width: auto;
    margin-right: 50px;
}
.ocs2-orderNow-box{
    display: flex;
    align-items: center;
}
.ocs2-orderNow-btnn{
    text-decoration: none;
}
.ocs2-orderNow-time{
    height: 30px;
    width: auto;
    margin-left: 25px;
}
.ocs2-bannerone-phoneNo{
    display: flex;
    margin-left: 25px;
    align-items: center;
    color: white;
}
.ocs2-bannerone-phoneNo-icon{
    font-size: 26px;
    color: white;
    margin-right: 10px;
}
.ocs2-bannerone-phoneNo strong{
    color: white;
}
.ocs2-bannerone-phoneNo p{
    margin-bottom: 0px;
}

/* bannerone responsive*/
@media only screen and (max-width: 600px){
    .ocs2-bannerone{
        padding: 30px 10px;
        flex-direction: column;
    }
    .ocs2-bannerone-detail{
        width: 100%;
    }
    .ocs2-bannerone-detail-title{
        font-size: 16px;
        padding: 0 10px;
    }
    .ocs2-bannerone-detail-text{
        padding: 0 10px;
        font-size: 16px;
    }
    .ocs2-bannerone-image-box{
        margin: 20px 0;
    }
    .ocs2-bannerone-detail{
        padding: 10px;
    }
    .ocs2-orderNow-box{
        flex-direction: column;
        gap: 20px;
    }
}

/* tab responsive */

@media only screen and (min-width: 601px) and (max-width: 1200px){
    .ocs2-bannerone{
        padding: 30px 100px;
        flex-direction: column;
    }
    .ocs2-bannerone-detail{
        width: 100%;
    }
    .ocs2-bannerone-detail-title{
        font-size: 16px;
        padding: 0 10px;
    }
    .ocs2-bannerone-detail-text{
        padding: 0 10px;
        font-size: 16px;
    }
    .ocs2-bannerone-image-box{
        margin: 20px 0;
    }
    .ocs2-bannerone-detail{
        padding: 10px;
    }
    .ocs2-orderNow-box{
        flex-direction: column;
        gap: 20px;
    }
}

/* Services section */
.ocs2-services{
    margin-top: 70px;
}
.ocs2-services-heading{
    text-align: center;
    font-size: 35px;
}
.ocs2-services-heading strong{
    color: #295fa9;
}
.ocs2-services-heading span{
    font-weight: 300;
}
.ocs2-services-box{
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
}
.ocs2-services-icons{
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
}
.ocs2-services-card1{
    background-color: #999999;
    border-radius: 15px;
    text-align: center;
    padding: 20px;
    color: white;
}
.ocs2-services-card1-icon{
    font-size: 60px;
}
.ocs2-services-card1 h3{
    margin: 15px 0;
}
.ocs2-services-card2{
    border-radius: 15px;
    background-color: #F9F9F9;
    text-align: center;
    padding: 20px;
}
.ocs2-services-card2-icon{
    font-size: 60px;
    color: #295fa9;
}
.ocs2-services-card2 h3{
    color: #295fa9;
    margin: 15px 0;
}
.ocs2-services-name{
    width: 50%;
    margin-right: 25px;
}
.ocs2-services-name-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    padding-top: 15px;
}
.ocs2-services-name-grid span{
    border: 1px solid #999999;
    padding: 7px 15px;
    border-radius: 5px;
    font-weight: bold;
    color: #295fa9;
    text-align: center;
}
.ocs2-services-name-grid-last{
    background-color: #295fa9;
    color: white !important;
}
.ocs2-services-more-details{
    width: 100%;
    margin: 30px 0 70px 0;
}
.ocs2-carousel{
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    height: 200px;
    width: 235px;
    padding: 20px 30px;
    display: inline-block;
    text-align: center;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center ;
}
.ocs2-carousel-icon{
    color: #295fa9;
    font-size: 35px;
}
.ocs2-carousel p{
    margin-top: 7px;
}
.sc-ifAKCX{
    margin: 0 -38px 0 -20px;
}
.ocs2-services-more-details-box{
    display: flex;
    justify-content: center;
    align-items: center;
}


/* servicer responsive */

@media only screen and (max-width: 600px){
    .ocs2-services{
        padding: 0 10px;
    }
    .ocs2-services-heading{
        font-size: 18px;
    }
    .ocs2-services-box{
        flex-direction: column;
    }
    .ocs2-services-name{
        width: 100%;
    }
    .ocs2-services-name-grid{
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 50px;
    }
    .ocs2-services-icons{
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
    }
    .ocs2-carousel{
        height: auto;
        width: 80%;
        margin-bottom: 0;
    }
    .sc-ifAKCX{
        margin: 0;
    }
}

/* tab responsive services */
@media only screen and (min-width: 601px) and (max-width: 1200px){
    .ocs2-services{
        padding: 0 10px;
    }
    .ocs2-services-heading{
        font-size: 18px;
    }
    .ocs2-services-box{
        flex-direction: column;
    }
    .ocs2-services-name{
        width: 100%;
    }
    .ocs2-services-name-grid{
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 50px;
    }
    .ocs2-services-icons{
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
    }
    .ocs2-carousel{
        height: auto;
        width: 80%;
        margin-bottom: 0;
    }
    .sc-ifAKCX{
        margin: 0;
    }
}


/* ordering process */
.ocs2-order{
    margin-bottom: 70px;
}
.ocs2-order h2{
    text-align: center;
    font-size: 35px;
}
.ocs2-order p{
    text-align: center;
    margin-top: 10px;
    font-size: 18px;
}
.ocs2-order strong{
    color: #295fa9;
}
.ocs2-order span{
    font-weight: 300;
}
.ocs2-order-steps{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}
.ocs2-order-card{
    text-align: center;
    padding: 20px;
    width: 30%;
    background-color: #F9F9F9;
    border-radius: 15px;
}
.ocs2-order-icon{
    font-size: 50px;
    border-radius: 10px;
    margin-bottom: 10px;
    color: #295fa9;
}

/* ordering process responsive */
@media only screen and (max-width: 600px){
    .ocs2-order{
        padding: 0 10px;
    }
    .ocs2-order h2{
        font-size: 25px;
    }
    .ocs2-order-steps{
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }
    .ocs2-order-card{
        width: 100%;
        box-sizing: border-box;
    }
}

/* tab responsive */
@media only screen and (min-width: 601px) and (max-width: 1200px){
    .ocs2-order{
        padding: 0 10px;
    }
    .ocs2-order h2{
        font-size: 25px;
    }
    .ocs2-order-steps{
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }
    .ocs2-order-card{
        width: 100%;
        box-sizing: border-box;
    }
}


/* testimonials */
.ocs2-testimonials{
    margin-bottom: 70px;
}
.ocs2-testimonials h2{
    text-align: center;
    font-size: 35px;
}
.ocs2-testimonials span{
    font-weight: 300;
}
.ocs2-testimonials strong{
    color: #295fa9;
}
.ocs2-testimpnials-list{
    margin-top: 30px;
}
.ocs2-user-card{
    display: block;
    border: 1px solid #e7e7e7;
    padding: 50px 40px;
    border-radius: 15px;
    text-align: center;
    font-size: 18px;
    margin: 20px 10px;
    box-shadow: 1px 1px 15px #e3e3e3;
    height: 450px;
}
.c-id{
    padding:0 0 20px 0;
    color: #b3b3b3;
}
.ocs2-user-card-icon{
    padding: 20px !important;
    color: #999999;
}
.ocs2-user-card-star{
    margin: 20px 2px 5px 2px;
}
.ocs2-user-card h3, .ocs2-user-card-details{
    margin-top: 10px;
}
.ocs2-testimonials-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 55px;
}
.ocs2-img{
    height: 80px;
    width: 80px;
    margin-bottom: -60px !important;
}

/* testimonials responsive */
@media only screen and (max-width: 600px){
    .ocs2-testimonials{
        padding: 0 10px;
    }
    .ocs2-testimonials h2{
        font-size: 25px;
    }
    .ocs2-user-card{
        height: auto;
    }
}

/* tab responsive */
@media only screen and (min-width: 601px) and (max-width: 1200px){
    .ocs2-testimonials{
        padding: 0 10px;
    }
    .ocs2-testimonials h2{
        font-size: 25px;
    }
    .ocs2-user-card{
        height: auto;
    }
}

/* comparison */
.ocs2-comparison{
    margin-bottom: 70px;
}
.ocs2-comparison-title{
    text-align: center;
    font-size: 35px;
    
}
.ocs2-comparison-title strong{
    color: #295fa9;
}
.ocs2-comparison-title span{
    font-weight: 300;
}
.ocs2-comparison-details{
    text-align: center;
    margin-top: 20px;
}
.ocs2-comparison table{
    width: 100%;
    margin-top: 50px;
    border-collapse: collapse;
}
.ocs2-comparison tr{
    border: 1px solid #d4d4d4;
}
.ocs2-comparison-table-head{
    background: linear-gradient(#295fa9, white);
}
.ocs2-comparison-table-head th{
    padding: 15px 0;
    text-align: center;
}
.features-t{
    text-align: left !important;
    padding-left: 35px !important;
}
table td{
    text-align: center;
    padding: 10px;
    height: auto;
  
}

.ocs2-comparison-table-icon{
    display: flex;
    align-items: center;
    padding-left: 30px;

}
.ocs2-comparison-table-icons{
    font-size: 25px;
    color: #295fa9;
    margin-right: 10px;
 

}


/* comparison responsive */
@media only screen and (max-width: 600px){
       .ocs2-comparison{
        display: none;
       }
}
/* tab responsive */
@media only screen and (min-width: 601px) and (max-width: 1200px){
    .ocs2-comparison{
        padding: 0 10px !important;
        margin: 0;
    }
    .ocs2-comparison-title{
        font-size: 25px;
    }
   .ocs2-comparison-table-icons{
    display: none;
   }
   .rating{
    transform: rotate(90deg);
    margin: 30px 0;
   }
   table td{
    height: auto;
    padding: 20px 0;
   }
   .ocs2-comparison-table-head th{
    width: 20px !important;
   }
   .ocs2-comparison-table-icon{
    width: 40px;
   }
   body{
    overflow-x: hidden;
}
}


/* FAQS */
.ocs2-faqs{
    margin-bottom: 70px;
}
.ocs2-faqs-title{
    text-align: center;
    margin-bottom: 50px;
    font-size: 35px;
    color: #295fa9;
    font-weight: bold;
}
.ocs2-faqs-boxes{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.accordian-box-image{
    width: 50%;
}
.accordian-box-image img{
    width: 100%;
}
.accordian-boxes{
    width: 50%;
    margin-left: 20px;
}
.accordian-header{
    margin-top: 20px;
    color: #333333;
    background-color: white;
    box-shadow: none;
    border: 1px solid #b3b3b3;
    border-radius: 15px;
    width: 100%;
}
.accordian-header-icon{
    font-size: 25px;
}
.accordion-title{
    width: 100%;
    margin: 5px 0;
    text-align: left;
    font-size: 18px;
}
.accordion-body{
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    padding: 20px !important;
    margin: 20px 0 0 0;
}

/* faqs responsive */
@media only screen and (max-width: 600px){
    .ocs2-faqs{
        padding: 0 10px;
    }
    .ocs2-faqs-title{
        font-size: 25px;
    }
    .ocs2-faqs-boxes{
        flex-direction: column;
    }
    .accordian-box-image{
        width: 100%;
        margin-bottom: 50px;
    }
    .accordian-boxes{
        width: 100%;
        margin: 0 10px;
    }
}
/* tab responsive */
@media only screen and (min-width: 601px) and (max-width: 1200px){
    .ocs2-faqs{
        padding: 50px;
    }
    .ocs2-faqs-title{
        font-size: 25px;
    }
    .ocs2-faqs-boxes{
        flex-direction: column;
    }
    .accordian-box-image{
        width: 100%;
        margin-bottom: 50px;
    }
    .accordian-boxes{
        width: 100%;
    }
}

/* footer */
.ocs2-footer-top{
    background: linear-gradient(#999999, #295fa9);
    padding: 40px;
    z-index: 10;
    margin-bottom: 50px;
}
.ocs2-footer-top-bg{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ocs2-footer-banner-title{
    color: white;
}
.ocs2-footer-banner-title h2{
    margin-bottom: 10px;
}
.ocs2-footer-banner-cta{
    display: flex;
}
.ocs2-footer-middle-outer{
    background-color: #333333;
}
.ocs2-footer-middle{
    padding: 100px 0 40px 0;
    display: flex;
    align-items: top;
    justify-content: space-between;
}
.ocs2-footer-logo{
    display: flex;
    flex-direction: column;
}
.footer-badge{
    height: 170px;
}
.ocs2-footer-menu h3{
    color: white;
    margin-bottom: 20px;
}
.ocs2-footer-menu ul{
    list-style: none;
    margin-left: -30px !important;
}
.ocs2-footer-menu ul li{
    margin-bottom: 0px;
    cursor: pointer;
    color: white !important;
}
.ocs2-footer-menu-link{
    color: white;
    text-decoration: none;
    cursor: pointer;
}
.ocs2-footer-menu-link:hover{
    color: #999999;
}
.ocs2-footer-contact h3{
    color: white;
    margin-bottom: 20px;
}
.ocs2-footer-contact ul{
    list-style: none;
    color: white;
}
.ocs2-footer-contact ul li{
    margin-bottom: 7px;
    cursor: pointer;
}
.ocs2-footer-payment{
    margin-top: 20px;
}
.ocs2-footer-icon{
    color: #295fa9;
    margin-right: 10px;
}
.ocs2-footer-disclaimer{
    background-color: #333333;
    padding: 0 160px;
}
.ocs2-footer-disclaimer p{
    text-align: center;
    color: white;
    padding-bottom: 40px;
}
.ocs2-footer-copyright{
    background-color: #295fa9;
    color: white;
    margin: -16px 0;
}
.ocs2-footer-copyright p{
    text-align: center;
    padding: 30px;
}
.social-media{
    display: flex;
    gap: 15px;
}
.social-media li{
    border: 1px solid white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
}
.social-media a{
    color: white;
}
.social-media a:hover{
    color: #999999;
}


/* footer responsive */
@media only screen and (max-width: 600px){
    .ocs2-footer-top{
        margin: 0 10px;
        margin-bottom: 30px;
    }
    .ocs2-footer-top-bg{
        flex-direction: column;
    }
    .ocs2-footer-banner-cta{
        margin-top: 30px;
        flex-direction: column;
        gap: 20px;
    }
    .ocs2-footer-middle{
        padding: 20px 10px;
        flex-direction: column;
    }
    .ocs2-footer-logo{
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .footer-badge{
        width: 40%;
        height: auto;
        margin-bottom: 20px;
    }
    .ocs2-footer-disclaimer{
        padding: 0 10px;
    }
    .ocs2-footer-menu{
        text-align: center;
    }
    .social-media{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ocs2-footer-contact{
        display: none;
    }
    .ocs2-footer-disclaimer{
        padding: 20px;
    }
}

/* tab responsive */
@media only screen and (min-width: 601px) and (max-width: 1200px){
    .ocs2-footer-top{
        margin: 0 10px;
        margin-bottom: 30px;
    }
    .ocs2-footer-top-bg{
        flex-direction: column;
    }
    .ocs2-footer-banner-cta{
        margin-top: 30px;
        flex-direction: column;
        gap: 20px;
    }
    .ocs2-footer-middle{
        padding: 20px 10px;
        flex-direction: column;
    }
    .ocs2-footer-logo{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .ocs2-footer-logo .site-logo{
        width: auto;
        height: 60px;
    }
    .footer-badge{
        width: 30%;
        height: auto;
    }
    .ocs2-footer-disclaimer{
        padding: 0 10px;
    }
}

/* celculator */
.ocs2-calculator-outerbox{
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box !important;
}
.calculator-top{
    background-color: #295fa9;
    border-radius: 15px 15px 0 0;
    box-sizing: border-box !important;
    text-align: center;
    padding: 0 30px 3px 30px;
}
.calculator-top .discount{
    background-color: #999999;
    border-radius: 0 0 15px 15px;
    padding: 7px ;
    color: white;
    margin-bottom: 10px;
}
.discount>p{
    margin-bottom: 0;
    font-weight: bold;
}
.discount-desc{
    color: white;
}
.calculator-bottom{
    border: 1px solid #295fa9;
    border-radius: 0 0 15px 15px;
    padding: 30px;
    box-sizing: border-box !important;
} 
.form-top{
    display: flex;
    gap: 20px;
}
.contact-form{
    box-sizing: border-box !important;   
}
.action{
    text-align: center;
    margin-bottom: 20px;
}
.contact-form label{
    margin-bottom: 5px;
}
#u-subject{
    margin-bottom: 20px;
}
.contact-form input{
    padding: 13px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #e3e3e3;
}
.form-guarentee{
    margin-top: 20px;
    margin-bottom: 0;
    text-align: center;
}

/* blog section */

.blog-section{
    margin: 70px 0 70px 0;
}
.blog-section-title{
    font-size: 35px;
    color: #295fa9;
    text-align: center;
    font-weight: bold;
}
.blog-section-posts{
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

/* blog section responsive */
@media only screen and (max-width: 700px){
    .blog-section-posts{
        grid-template-columns: repeat(1, 1fr);
        
    }  
}

@media only screen and (min-width: 701px) and (max-width: 1200px){
    .blog-section-posts{
        grid-template-columns: repeat(2, 1fr);
        box-sizing: border-box !important;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1350px){
    .blog-section-posts{
        grid-template-columns: repeat(3, 1fr);
    }
}

/* capella */

.flexpath-data{
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000076;
    z-index: 999;
}
.data-inner{
    width: 50%;
    height: 80vh;
    background-color: white;
}
.path-title{
    background-color: #295fa9;
    display: flex;
    justify-content: space-between;
    padding: 7px 30px;
    color: white;
}
.close-btn{
    background-color: #999999;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.path-inner-title{
    font-size: 20px;
}
.path-details{
    padding: 30px;
    overflow-y: auto;
    height: 90%;
}
.path-details p{
    font-size: 18px;
}
.capella-hero-banner{
    border-radius: 5px !important;
    padding: 10px 20px !important;
}


/* features section popups */
.features-popup{
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000076;
    z-index: 999;
}
.features-popup-inner{
    width: 50%;
    height: 200px;
    background-color: white;
}
.path-details{
    text-align: center;
}
/* features section popups reaponsive */

@media only screen and (max-width: 700px){
    .features-popup-inner{
        width: 85%;
        height: auto;
    }  
}

/* Contentone */
.contentone{
    margin-bottom: 70px;
    display: flex;
    gap: 30px;
    height: 475px;
}
.content{
    width: 70%;
    height: 100%;
    overflow-y: auto;
    border: 2px solid #295fa9;
    border-radius: 10px;
    padding: 20px 30px;
}
.image-box{
    width: 30%;
}
.girl-image{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
@media only screen and (max-width: 1000px){
    .contentone{
        flex-direction: column;
    }
    .content{
        width: 100%;
        
    }
    .image-box{
        display: none;
    }
}